.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Adjust as needed */
    visibility: hidden; /* Initially hidden */
  }
  
  .modal.show {
    visibility: visible; /* Show the modal when the 'show' class is applied */
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 900; /* Lower than modal z-index but higher than content z-index */
  }
  