/* CSS */
.custom-button {
    /* Your custom styles */
    background-color: #3498db;
    color: #fff;
    border-radius: 5px;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 14px;
  }
  
  .custom-button:hover {
    /* Styles on hover */
    background-color: #2980b9;
    /* Adjust hover effects as needed */
  }
  