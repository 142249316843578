.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-main {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Adjust margin as needed */
  }
  
  .icons-container {
    display: flex;
    align-items: center; /* Center items vertically */
    margin-right: 10px; /* Add margin as needed */
  }
  
  .header-icons {
    font-size: 20px; /* Adjust the size as needed */
  }
  